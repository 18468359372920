@font-face {
font-family: '__Ubuntu_737716';
src: url(/_next/static/media/7f7f7f543df27dc4-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__Ubuntu_Fallback_737716';src: local("Arial");ascent-override: 91.04%;descent-override: 18.46%;line-gap-override: 2.74%;size-adjust: 102.37%
}.__className_737716 {font-family: '__Ubuntu_737716', '__Ubuntu_Fallback_737716'
}

/* roboto-cyrillic-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-cyrillic-ext-300-normal.cd7c5715.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* roboto-cyrillic-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-cyrillic-300-normal.88798412.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* roboto-greek-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-greek-ext-300-normal.bc5ce703.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-greek-300-normal.25dc89b0.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-vietnamese-300-normal.b3d3e960.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* roboto-latin-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-latin-ext-300-normal.37d4965d.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* roboto-latin-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-latin-300-normal.a4eae32d.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* roboto-cyrillic-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-cyrillic-ext-400-normal.d7827ae3.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* roboto-cyrillic-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-cyrillic-400-normal.2d9c9d60.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* roboto-greek-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-greek-ext-400-normal.2b547ded.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-greek-400-normal.63e6dc18.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-vietnamese-400-normal.c95fc061.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* roboto-latin-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-latin-ext-400-normal.21abc8c8.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* roboto-latin-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-latin-400-normal.f2894edc.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* roboto-cyrillic-ext-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-cyrillic-ext-500-normal.a1b5c90d.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* roboto-cyrillic-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-cyrillic-500-normal.aa68ea54.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* roboto-greek-ext-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-greek-ext-500-normal.7ea6cffa.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-greek-500-normal.533b03d2.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-vietnamese-500-normal.7f8c0554.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* roboto-latin-ext-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-latin-ext-500-normal.85ebfb55.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* roboto-latin-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-latin-500-normal.3170fd9a.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* roboto-cyrillic-ext-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-cyrillic-ext-700-normal.dd3651fb.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* roboto-cyrillic-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-cyrillic-700-normal.258a358e.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* roboto-greek-ext-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-greek-ext-700-normal.a8d16efd.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-greek-700-normal.432b858b.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-vietnamese-700-normal.72bf832f.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* roboto-latin-ext-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-latin-ext-700-normal.6af98c24.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* roboto-latin-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-latin-700-normal.71b2beb8.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* material-icons-base-400-normal*/
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/material-icons-base-400-normal.a987d159.woff2) format('woff2'), url(/_next/static/media/material-icons-base-400-normal.6a5d2b3f.woff) format('woff');
  
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}
/* material-icons-outlined-all-400-normal*/
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/material-icons-outlined-all-400-normal.c8a31db9.woff2) format('woff2'), url(/_next/static/media/material-icons-outlined-all-400-normal.53c336bf.woff) format('woff');
  
}
.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}
